<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="addCost()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

        </v-card>
        
        <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>

                  <h2>Wybrany projekt: {{projects.name}}</h2>

                  <v-text-field
                    v-model="name"
                    label="Tutuł wydatku"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>


                  <v-textarea
                    v-model="description"
                    label="Opis"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-textarea>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="price_netto"
                        label="Cena netto"
                        color="primary"
                        type="Number"
                        :rules="$store.state.rules.not_null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="price_brutto"
                        label="Cena brutto"
                        color="primary"
                        type="Number"
                        :rules="$store.state.rules.not_null"
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-menu
                    v-model="date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_created"
                        label="Data kosztu"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_created"
                      @input="date_picker = false"
                      locale="pl-pl"
                      color="primary"
                      :max="today"
                    ></v-date-picker>
                  </v-menu>
                
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: false,
        to: '/projekty',
      },
      {
        text: 'Projekt',
        disabled: false,
        to: '/projekt',
      },
      {
        text: 'Nowy koszt',
        disabled: true,
        to: '/dodaj-koszt',
      },
    ],

    date_picker: false,
    date_created: new Date().toISOString().substr(0, 10),

    name: '',
    description: '',
    price_netto: null,
    price_brutto: null,
    selected_project: null,

    projects: {},

    calculate: false,
    tax: 23,

    
    today: new Date().toISOString().substr(0, 10),
  }),
  methods: {
    addCost() {
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');
        let formData = new FormData();
        
        formData.append("name", this.name);
        formData.append("description", this.description);
        formData.append("price_netto", this.price_netto);
        formData.append("price_brutto", this.price_brutto);
        formData.append("project_id", this.id);
        formData.append("date_created", this.global_flipDate(this.date_created));
        
        // console.log(this.selected_workers);
        // for(const item in this.selected_workers){
        //   formData.append("users["+item+"][user_id]", this.selected_workers[item]);
        // }

        this.$axios({url: this.$store.state.api +'/project_cost', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/projekt/'+this.id);
            this.$store.commit("snackbar", {
              text: "Dodano koszt",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
              this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
  },
  mounted(){
    this.id = this.$route.params.id;
    this.breadcrumb[2].to = '/projekt/'+this.id;

    this.$store.commit('loader');
    // let test = this.global_flipDate('2021-02-05');

    // Pobieranie listy projektow
    this.$axios({url: this.$store.state.api +'/project/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.projects = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
</style>